/* GLOBAL */
p {
  word-break: break-word;
  white-space: normal;
}



strong {
  color: orange;
  font-weight: bold
}

a {
  color: orange;
  cursor:pointer;
  font-weight: bold
}

button:disabled {
  background-color: gray !important;
  cursor: not-allowed;
}

.new-element {
  margin-bottom: 20px !important;
}

.full-img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.full-img-square {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.full-img-norescale {
  width: 100%;
  height: auto;
}

.blog-product-img-center {
  width: 60%;
  height: auto;
  align-self: center;
}

.blog-alert-container {
  border:1px solid red;
  padding: 10px
}

.toast-product-img {
  width: 125px;
  height: 125px;
  object-fit: contain;
}

.author-text {
 color: rgb(0, 199, 0);
 font-size: 1.2em;
}

.avatar-img-container {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
}

.avatar-img-rounded {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: sover;

}

.avatar-img {
  width: 60px;
  height: auto;
  margin-right: 10px;
  border-radius: 100%;
}

.img-detail {
  color: gray;
}

.input-double-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.input-double {
  width: 40%;
}

.full-input {
  width: calc(100% - 20px);
  height: 40px;
  padding-inline: 10px;
  border-radius: 5px;
  outline-width: 1px;
  border: 1px solid gray;
  font-size: 1em;
}

.full-stripe {
  width: calc(100% - 20px);
  padding: 13px;
  padding-inline: 10px;
  border-radius: 5px;
  outline-width: 1px;
  border: 1px solid gray;
  font-size: 1em;
}

.full-dropdown {
  width: calc(100%) !important;
  max-height: 100% !important;
  height: 40px !important;

  border-radius: 5px !important;

  border: 1px solid gray !important;
  font-size: 1em !important;
}

.legal-text {
  width: 100%;
}

/* HOME */

.container {
  padding-inline: 20px;
  max-width: 1000px;
  display: flex;
  margin: auto;
}

.header-img {
  display: flex;
  margin: auto;
  height: 100px;
}

.header-second-img {
  display: flex;
  margin: auto;
  height: 100px;
  max-width: 500px;
  object-fit: contain;
}

.header {
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid lightgray;
}

.author-img {
  height: 40px;
}

.home-author {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-container {
  display: flex;
}

.home-resume {
  padding: 10px;
  background-color: rgb(255, 222, 161);
  border-radius: 3px;
}

.home-author-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-title {
  margin-top: 0px;
  font-size: 2.5em;
}

.home-subtitle {
  font-size: 2em;
  color: rgb(80, 80, 80);
}

.blog-title {
  font-size: 2em;
  font-weight: bold;
  color: orange;
}

.blog-description-detail {
  font-size: 1.5em;
  font-weight: 600;
}

.blog-description-detail-nobold {
  font-size: 1.5em;
}

.blog-description-detail-important {
  font-size: 1.8em;
  text-align: center;
  color: red;
  font-weight: 600;
}

.blog-description-detail-important-nocenter {
  font-size: 1.8em;
  color: red;
  font-weight: 600;
}

.blog-description-detail-smaller {
  font-size: 1.3em;
  font-weight: 600;
}

.blog-description-subdetail {
  font-size: 1.5em;

  color: rgb(80, 80, 80);
}

.blog-description-subdetail-smaller {
  font-size: 1.3em;

  color: rgb(80, 80, 80);
}

.blog-detail-text {
  font-size: 1.3em;
}

.text-underline {
  text-decoration: underline;
}

.text-underline-blue {
  text-decoration: underline;
  color: blue;
}

.hyperlink {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.line-text {
  text-decoration: line-through
}


.orange-container {
  padding-block: 2px;
  text-align: center;
  background-color: rgb(52, 98, 159);
  border-radius: 0px;
  color: white;
  border-radius: 5px;
  font-weight: 600;
}

.blog-detail-img {
  display: flex;
  flex-direction: row;
}

.blog-product-img {
  max-width: 200px;
}

.blog-product-center-img {
  max-height: 300px;
  display: flex;
  margin: auto;
}

.blog-button-clickhere {
  width: 100%;
  padding: 20px;
  background-color: rgb(0, 199, 0);
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 1.4em;
  border-style: none;
  cursor: pointer;
}

.blog-review {
  padding: 10px;
  background-color: rgb(230, 230, 230);
  border-radius: 3px;
}

.blog-review-text {
  color: rgb(41, 41, 41);
  font-style: italic;
  margin-block: 0px;
}

.blog-review-user {
  margin-block-end: 0px;
  margin-block-start: 10px;
  color: rgb(41, 41, 41);
}

.blog-rating-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blog-img-left {
  max-width: calc(100%);
  width: 250px;
  height: auto;
  margin-right: 20px;
}

.blog-img-rating {
  max-width: calc(100%);
  width: 350px;
  height: auto;
  margin-right: 20px;
}

.blog-review-carousel {
  display: flex;
  flex-direction: row;
  max-height: 400px;
  justify-content: space-around;
  align-items: center;
}

.blog-review-carousel-reverse {
  display: flex;
  flex-direction: row-reverse;
  max-height: 400px;
  justify-content: space-around;
  align-items: center;
}

.blog-review-carousel-text-container {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  align-items: flex-end;
}

.blog-review-carousel-img {
  width: auto;
  height: 350px;
  object-fit: contain;
}

.blog-review-carousel-text {
  font-size: 1.15em;
  text-align: center;
  font-weight: 600;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.avatar-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding-inline: 40px;
  min-height: 100px;
  background-color: #062921;
  padding-block: 20px;
}

.footer-img {
  display: flex;
  height: 70px;
  margin-bottom: 20px;
}

.footer-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.sticky-item-container {
  position: sticky;
}

.sticky-item {
  cursor: pointer;
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 20px;
  width: 180px;
  border-style: solid;
  border-color: rgb(211, 211, 211);
  border-width: 1px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4); */
  margin-left: 20px;
  padding: 20px;
}


.sticky-button {
  font-size: .9em;
  font-weight: bold;
  color: white;
  font-weight: bold;
  padding: 20px;
  background-color: rgb(0, 199, 0);
  border-radius: 5px;
  color: white;
  border-style: none;
  cursor: pointer;
}

.sticky-title {
  display: flex;
  font-size: 1.1em;
  font-weight: bold;
  color: orange;
  margin: auto;
}

.sticky-subtitle {
  display: flex;
  font-size: 1.2em;
  font-weight: bold;
  color: black;
  margin: auto;
  text-align: center;
}

/* ORDER */

.header-order {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.order-container {
  padding-inline: 20px;
  max-width: 1200px;
  display: flex;
  margin: auto;
}

.order-special-offer-text {
  text-align: center;
  font-size: 1.2em;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.order-img-item {
  display: flex;
  margin: auto;
  height: 200px;
}

.order-article-photo-description {
  padding: 20px;

  display: flex;
  margin: auto;
  background-color: white;
  border-radius: 20px;
}

.order-article-photo-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-article-photo {
  height: 300px;
  /* aspect-ratio: 1; */
}

.order-article-description-text {
  font-size: 1.2em;
}

.order-article-description-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.order-article-description {
  display: flex;

  align-items: center;
}

.order-article-step-description {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.order-article-step-1-container {
  padding: 20px;
  width: calc(60% - 50px);

  background-color: white;
  border-radius: 20px;
}

.order-article-step-1-reduction-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.order-article-step-1-reduction {
  width: 12%;
  height: auto;
  aspect-ratio: 1;
}

.order-article-step-1-reduction-text-container {
  width: 80%;
}

.order-article-step-1-article-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.order-article-step-1-article-price-most-sold-container {
  position: relative;
  height: 22px;
  width: 100%;
}

.order-article-step-1-article-price-most-sold {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  transform-style: preserve-3d;
}

.order-article-step-1-article-price-most-sold::before {
  content: "";
  position: absolute;
  height: calc(100% + 20px);
  width: calc(100% + 50px);

  right: -25px;

  border-radius: 3px;
  transform: translateZ(-1px);
  border: 1px solid black;
  background: rgb(213, 255, 216);
  z-index: 0;
}

.order-article-step-1-article-price-checkbox-most-sold {
  position: absolute;
  width: 80%;
}

.order-article-step-2-container {
  padding: 20px;
  width: calc(40% - 50px);
  background-color: white;
  border-radius: 20px;
}

.order-article-step-title {
  font-size: 1.2em;
  font-weight: bold;
}

.order-footer {
  padding-block: 50px;
}

.order-footer-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-footer-nav-text {
  color: rgb(190, 190, 190);
}

.order-article-payment-button {
  width: 100%;
  padding: 15px;
  background-color: rgb(0, 199, 0);
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 1em;
  border-style: none;
  cursor: pointer;
}

.order-security-image {
  display: flex;
  margin: auto;
  max-width: 300px;
  height: auto;
}

.order-card-image {
  display: flex;

  max-width: 150px;
  height: auto;
}

.order-popup-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.order-popup {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  max-width: 400px;
  width: 80%;
  max-height: 300px;
  height: fit-content;
  padding: 20px;
  margin: auto;
  border-radius: 10px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: scroll;
}

.order-confirmation-ok-button {
  padding: 5px;
  background-color: transparent;
  border-radius: 5px;
  color: green;
  font-weight: bold;
  font-size: 1.1em;
  border-style: none;
  cursor: pointer;
}

@keyframes custom-enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes custom-exit {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width: 1000px) {
  .sticky-item-container {
    display: none;
  }
}

@media (max-width: 800px) {
  .order-article-photo-description {
    align-items: center;
    flex-direction: column;
  }

  .order-article-step-description {
    flex-direction: column;
  }

  .order-article-step-1-container {
    width: calc(100% - 40px);
    margin-bottom: 20px;
  }

  .order-article-step-2-container {
    width: calc(100% - 40px);
    margin-bottom: 20px;
  }

  .order-article-description-container {
    width: auto;
  }

  .blog-rating-container {
    flex-direction: column;
  }

  .blog-img-rating {
    margin-right: 0px;
  }

  .header-second-img {
    height: 65px;
  }

  .order-security-image {
    width: 200px;
  }

  .order-article-photo {
    height: 200px;
  }

  .order-article-photo-container {
    width: 100%;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-img {
    margin: auto;
    margin-bottom: 20px;
  }
}